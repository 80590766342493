import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { OktaAuthStateService } from '@okta/okta-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  PhoneState,
  getTextNotificationsOptInStatus,
  selectCombinedOptInStatus,
  selectGetTextNotificationsOptInStatus,
  selectSaveTextNotificationsOptInStatus,
} from '@patient-ui/patient-web/store';
import { RequestStatus } from '@patient-ui/shared/enums';
import { GetTextNotificationsOptInData } from '@patient-ui/shared/models';

@Component({
  selector: 'patient-ui-labservices-optin',
  templateUrl: './labservices-optin.component.html',
  styleUrls: ['./labservices-optin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabservicesOptinComponent implements OnDestroy, OnInit {
  currentOptInStatus$ = this.phoneStore.select(selectCombinedOptInStatus);
  destroyed = new Subject();
  lpid = '';
  savedOptInSuccessfully$ = new BehaviorSubject<boolean>(false);
  showSpinner$ = new BehaviorSubject<boolean>(false);
  techDifficultiesMsg$ = new BehaviorSubject<boolean>(false);

  constructor(
    public authStateService: OktaAuthStateService,
    private phoneStore: Store<PhoneState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.lpid = this.route.snapshot.paramMap.get('lpid') ?? '';
    const optInData: GetTextNotificationsOptInData = {
      lpid: this.lpid,
    };
    this.phoneStore.dispatch(
      getTextNotificationsOptInStatus({ optInData: optInData })
    );
    this.phoneStore
      .select(selectSaveTextNotificationsOptInStatus)
      .pipe(takeUntil(this.destroyed))
      .subscribe((saveOptInStatus) => {
        switch (saveOptInStatus) {
          case RequestStatus.Pending:
            this.showSpinner$.next(true);
            break;
          case RequestStatus.Failure:
            this.showSpinner$.next(false);
            this.techDifficultiesMsg$.next(true);
            this.savedOptInSuccessfully$.next(false);
            break;
          case RequestStatus.Success:
            this.showSpinner$.next(false);
            this.techDifficultiesMsg$.next(false);
            this.savedOptInSuccessfully$.next(true);
            break;
        }
      });
    this.phoneStore
      .select(selectGetTextNotificationsOptInStatus)
      .pipe(takeUntil(this.destroyed))
      .subscribe((getCurrentOptInStatus) => {
        switch (getCurrentOptInStatus) {
          case RequestStatus.Pending:
            this.showSpinner$.next(true);
            break;
          case RequestStatus.Failure:
            this.showSpinner$.next(false);
            this.techDifficultiesMsg$.next(true);
            break;
          case RequestStatus.Success:
            this.showSpinner$.next(false);
            this.techDifficultiesMsg$.next(false);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
