import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  PhoneService,
  PhoneState,
  RecaptchaService,
  saveTextNotificationsOptIn,
  selectBillingServices,
} from '@patient-ui/patient-web/store';
import {
  IPhoneValidationResponse,
  OptInStatus,
  SaveTextNotificationsOptInData,
} from '@patient-ui/shared/models';

@Component({
  selector: 'patient-ui-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneFormComponent implements OnDestroy, OnInit {
  @Input() lpid = '';
  @Input() currentOptInStatus!: OptInStatus;
  @Input() showSpinner = false;

  currentMobileNumber = '';
  consentProvidedCbChecked = false;
  destroyed = new Subject();
  invalidPhoneMsg$ = new BehaviorSubject<boolean>(false);
  invalidProvideConsent$ = new BehaviorSubject<boolean>(false);
  noCurrrentNumber = true;
  optInForm!: FormGroup;

  recaptchaKey = '';
  recaptchaRequired = false;
  recaptchaToken = '';

  techDifficultiesMsg$ = new BehaviorSubject<boolean>(false);

  constructor(
    private builder: FormBuilder,
    private phoneService: PhoneService,
    private phoneStore: Store<PhoneState>,
    private recaptchaService: RecaptchaService
  ) {
    this.recaptchaService.ensureRecaptchaIsSet().then((key) => {
      this.recaptchaRequired = true;
      this.recaptchaKey = key;
    });
  }
  ngOnInit() {
    if (
      this.currentOptInStatus === 'optedInBillingServicesOnly' ||
      this.currentOptInStatus === 'optedInAll'
    ) {
      this.phoneStore
        .select(selectBillingServices)
        .pipe(takeUntil(this.destroyed))
        .subscribe((billingServices) => {
          this.currentMobileNumber = billingServices.maskedPhoneNumber;
          this.noCurrrentNumber = this.currentMobileNumber.length === 0;
        });
    }
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  initForm() {
    // Phone number is required unless we have a current mobile number
    const numberValidators = this.noCurrrentNumber ? [Validators.required] : [];

    // Define the type for form controls
    const formControls: {
      phone: FormGroup;
      terms?: FormControl;
      captcha?: FormControl;
    } = {
      phone: new FormGroup({
        number: new FormControl('', numberValidators),
      }),
    };

    // Add terms and captcha if not 'optedInAll'
    if (this.currentOptInStatus !== 'optedInAll') {
      formControls.terms = new FormControl(
        { value: '', disabled: this.noCurrrentNumber },
        Validators.required
      );
      formControls.captcha = new FormControl('', Validators.required);
    }

    this.optInForm = this.builder.group(formControls);

    this.subscribeToFormChanges();
  }

  subscribeToFormChanges() {
    // Subscribe to form value changes to enable/disable terms control
    // and update error messages
    this.optInForm.valueChanges.subscribe(() => {
      const phoneControl = this.optInForm.get('phone.number');
      const termsControl = this.optInForm.get('terms');

      if (phoneControl) {
        this.invalidPhoneMsg$.next(
          phoneControl.invalid && (phoneControl.dirty || phoneControl.touched)
        );

        // Using placeholder to show current masked mobile number
        // if it exists phone field can be empty and but checkbox will be required
        if (termsControl) {
          if (
            phoneControl.valid ||
            (phoneControl.value.length === 0 && !this.noCurrrentNumber)
          ) {
            termsControl.enable({ emitEvent: false });
            termsControl.updateValueAndValidity({
              onlySelf: true,
              emitEvent: false,
            }); // Update validity when re-enabling
          } else {
            termsControl.setValue(false, { emitEvent: false }); // Reset value when disabling
            termsControl.disable({ emitEvent: false });
            termsControl.updateValueAndValidity({
              onlySelf: true,
              emitEvent: false,
            });
          }

          // Terms checkbox error messages
          this.invalidProvideConsent$.next(
            !termsControl.value && (termsControl.dirty || termsControl.touched)
          );
        }
      }
    });
  }
  resolveRecaptcha(token: string | null) {
    if (token !== null) {
      this.recaptchaRequired = false;
      this.recaptchaToken = token;
    }
  }

  async validatePhone(): Promise<Partial<IPhoneValidationResponse>> {
    const phoneNumber = this.optInForm.get('phone.number')?.value;
    return new Promise((resolve, reject) => {
      if (!phoneNumber) {
        resolve({ status: 'invalidnumber' });
      } else if (!this.optInForm.get('phone.number')?.valid) {
        resolve({ status: 'invalidnumber' });
      } else {
        this.phoneService.validatePhone(phoneNumber).subscribe(
          (response) => {
            resolve(response);
          },
          (_error) => {
            reject({ status: 'Error' });
          }
        );
      }
    });
  }
  async onSubmit() {
    this.optInForm.markAllAsTouched();
    this.optInForm.updateValueAndValidity();
    const phoneControl = this.optInForm.get('phone.number');
    const hasTermsAndCaptha = this.currentOptInStatus !== 'optedInAll';
    let formIsValid = !this.optInForm.invalid;

    if (hasTermsAndCaptha) {
      formIsValid = formIsValid && !this.optInForm.get('terms')?.disabled;
    }

    if (formIsValid) {
      // Only validate phone number if it's been entered and not using existing
      if (phoneControl?.value.length > 0) {
        const response = await this.validatePhone();
        if (
          !response.status ||
          response.status.trim().toLowerCase() === 'error'
        ) {
          this.techDifficultiesMsg$.next(true);
          return;
        } else if (
          response.status.trim().toLowerCase() === 'invalidnumber' ||
          response.type?.trim().toLowerCase() !== 'mobile'
        ) {
          this.optInForm.get('phone.number')?.setErrors({ invalid: true });
          this.invalidPhoneMsg$.next(true);
          if (hasTermsAndCaptha) {
            this.optInForm.get('terms')?.setValue(false);
          }
          return;
        }
      }
      const inputPhone = this.optInForm.get('phone.number')?.value;
      const mobilePhoneNumber = this.currentMobileNumber;
      const recaptchaToken = hasTermsAndCaptha
        ? this.optInForm.controls.captcha.value
        : '';
      const optInData: SaveTextNotificationsOptInData = {
        lpid: this.lpid,
        mobileConsent: true,
        mobilePhone: inputPhone.length > 0 ? inputPhone : mobilePhoneNumber,
        recaptchaToken: recaptchaToken,
      };
      this.phoneStore.dispatch(
        saveTextNotificationsOptIn({ optInData: optInData })
      );
    }
  }
}
