import { createReducer, on } from '@ngrx/store';

import { RequestStatus } from '@patient-ui/shared/enums';

import * as patientActions from './phone.actions';

export const phoneFeatureKey = 'phone';

export type PhoneState = {
  billingOptInPhoneRequestStatus: RequestStatus;
  billingOptedIn: boolean;
  billingMessage: string;
  saveTextNotificationsOptInRequestStatus: RequestStatus;
  getTextNotificationsOptInRequestStatus: RequestStatus;
  billingOptin: {
    optedIn: boolean;
    maskedPhoneNumber: string;
  };
  labServiceOptin: {
    optedIn: boolean;
    maskedPhoneNumber: string;
  };
};

export const initialState: PhoneState = {
  billingOptInPhoneRequestStatus: RequestStatus.NotSent,
  billingOptedIn: false,
  billingMessage: '',
  saveTextNotificationsOptInRequestStatus: RequestStatus.NotSent,
  getTextNotificationsOptInRequestStatus: RequestStatus.NotSent,
  billingOptin: {
    optedIn: false,
    maskedPhoneNumber: '',
  },
  labServiceOptin: {
    optedIn: false,
    maskedPhoneNumber: '',
  },
};

export const phoneReducer = createReducer(
  initialState,
  on(patientActions.billingTextsOptIn, (state) => ({
    ...state,
    billingOptInPhoneRequestStatus: RequestStatus.Pending,
  })),
  on(patientActions.billingTextsOptInFailure, (state) => ({
    ...state,
    billingOptInPhoneRequestStatus: RequestStatus.Failure,
  })),
  on(patientActions.billingTextsOptInSuccess, (state, { response }) => ({
    ...state,
    billingOptedIn: response.optedIn,
    billingMessage: response.message,
    billingOptInPhoneRequestStatus: RequestStatus.Success,
  })),
  on(patientActions.saveTextNotificationsOptIn, (state) => ({
    ...state,
    saveTextNotificationsOptInRequestStatus: RequestStatus.Pending,
  })),
  on(patientActions.saveTextNotificationsOptInFailure, (state) => ({
    ...state,
    saveTextNotificationsOptInRequestStatus: RequestStatus.Failure,
  })),
  on(patientActions.saveTextNotificationsOptInSuccess, (state) => ({
    ...state,
    saveTextNotificationsOptInRequestStatus: RequestStatus.Success,
  })),
  on(patientActions.getTextNotificationsOptInStatus, (state) => ({
    ...state,
    getTextNotificationsOptInRequestStatus: RequestStatus.Pending,
  })),
  on(patientActions.getTextNotificationsOptInStatusFailure, (state) => ({
    ...state,
    getTextNotificationsOptInRequestStatus: RequestStatus.Failure,
  })),
  on(
    patientActions.getTextNotificationsOptInStatusSuccess,
    (state, { response }) => ({
      ...state,
      getTextNotificationsOptInRequestStatus: RequestStatus.Success,
      billingOptin: {
        optedIn: response.billingOptin?.optedIn ?? state.billingOptin.optedIn,
        maskedPhoneNumber:
          response.billingOptin?.maskedPhoneNumber ??
          state.billingOptin.maskedPhoneNumber,
      },
      labServiceOptin: {
        optedIn:
          response.labServiceOptin?.optedIn ?? state.labServiceOptin.optedIn,
        maskedPhoneNumber:
          response.labServiceOptin?.maskedPhoneNumber ??
          state.labServiceOptin.maskedPhoneNumber,
      },
    })
  )
);
