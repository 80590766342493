<div class="container-xl h-100 pt-2 mb-6">
  <div class="row mt-2">
    <div class="col">
      <ng-container *ngIf="(savedOptInSuccessfully$ | async) === false" [ngSwitch]="currentOptInStatus$ | async">
        <ng-container *ngSwitchCase="'optedInNone'">
          <h2 class="mt-6 mb-5">
            Sign Up for Text Messages
          </h2>
          <h3 class="mt-0 mb-5">
            Labcorp makes it easy to stay informed about your lab testing.
          </h3>
          <patient-ui-phone-form
            [currentOptInStatus]="'optedInNone'"
            [lpid]="lpid"
            [showSpinner]="(showSpinner$ | async) ? true : false"
          ></patient-ui-phone-form>
        </ng-container>
        <ng-container *ngSwitchCase="'optedInAll'">
          <h2 class="mt-6 mb-5">
            You are already signed up for text messages
          </h2>
          <h5 class="mt-0 mb-5">We send you messages using the number shown. Do you need to update your number?</h5>
          <patient-ui-phone-form
            [currentOptInStatus]="'optedInAll'"
            [lpid]="lpid"
            [showSpinner]="(showSpinner$ | async) ? true : false"
          ></patient-ui-phone-form>
        </ng-container>
        <ng-container *ngSwitchCase="'optedInBillingServicesOnly'">
          <h2 class="mt-6 mb-5">
            Your billing service texts come to this number
          </h2>
          <h5 class="mt-0 mb-5">
            We send you billing service messages to the number shown. Check the box and click Submit to receive lab service messages at this
            number also.
          </h5>
          <h5 class="mt-0 mb-5">
            You can change the number we need to contact you. This will change where we send billing service messages as well.
          </h5>
          <patient-ui-phone-form
            [currentOptInStatus]="'optedInBillingServicesOnly'"
            [lpid]="lpid"
            [showSpinner]="(showSpinner$ | async) ? true : false"
          ></patient-ui-phone-form>
        </ng-container>
      </ng-container>
      <patient-ui-confirm-and-sign-in-section
        *ngIf="(savedOptInSuccessfully$ | async) === true || (currentOptInStatus$ | async) !== 'optedInNone'"
        [showConfirmation]="(savedOptInSuccessfully$ | async) ? true : false"
      ></patient-ui-confirm-and-sign-in-section>
    </div>
    <div class="col-auto d-none d-md-block">
      <img
        loading="lazy"
        src="assets/images/female-in-lab-coat.png"
        width="358.59px"
        class="mx-5 my-6"
        alt="person in lab coat looking at tablet"
      />
    </div>
  </div>
</div>
