import { createAction, props } from '@ngrx/store';

import {
  BillingNotificationsOptIn,
  GetTextNotificationsOptInData,
  SaveTextNotificationsOptInData,
  TextNotificationsOptInResponse,
} from '@patient-ui/shared/models';

export interface PhoneOptInResponse {
  optedIn: boolean;
  message: string;
}

export const billingTextsOptIn = createAction(
  '[Phone] Billing Texts Opt-In',
  props<{ optInData: BillingNotificationsOptIn }>()
);

export const billingTextsOptInFailure = createAction(
  '[Phone] Billing Texts Opt-In Failure'
);

export const billingTextsOptInSuccess = createAction(
  '[Phone] Billing Texts Opt-In Success',
  props<{ response: PhoneOptInResponse }>()
);

export const saveTextNotificationsOptIn = createAction(
  '[Phone] Save Texts Notifications Opt-In',
  props<{ optInData: SaveTextNotificationsOptInData }>()
);

export const saveTextNotificationsOptInFailure = createAction(
  '[Phone] Save Texts Notifications Opt-In Failure'
);

export const saveTextNotificationsOptInSuccess = createAction(
  '[Phone] Save Texts Notifications Opt-In Success',
  props<{ response: string }>()
);

export const getTextNotificationsOptInStatus = createAction(
  '[Phone] Get Texts Notifications Opt-In Status',
  props<{ optInData: GetTextNotificationsOptInData }>()
);

export const getTextNotificationsOptInStatusFailure = createAction(
  '[Phone] Get Texts Notifications Opt-In Status Failure'
);

export const getTextNotificationsOptInStatusSuccess = createAction(
  '[Phone] Get Texts Notifications Opt-In Status Success',
  props<{ response: TextNotificationsOptInResponse }>()
);
