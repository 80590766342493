<ng-container *ngIf="showConfirmation; else showSignInTextTemplate">
  <h2 class="mt-6 mb-5">
    You're all set
  </h2>
  <h3 class="mt-0 mb-5">
    We'll send you a confirmation text shortly.
  </h3>
  <p>
    With a Labcorp Patient account, you can make appointments, review lab results, and pay your bills online, easily and securely. All in
    one convenient location.
  </p>
</ng-container>
<ng-template #showSignInTextTemplate>
  <hr class="w-100 border-dark mt-5" />
  <h4 class="mt-5 mb-5">
    Sign in to your Labcorp Patient account
  </h4>
  <p>
    With a Labcorp Patient account, you can view all your bills, pay securely anytime, and save your payment method to make bill pay even
    easier next time. All in one <br />
    convenient location.
  </p>
</ng-template>
<button
  [ngClass]="showConfirmation ? 'btn btn-min-width btn-primary mb-6 mt-1' : 'btn btn-min-width btn-primary-dark mb-6 mt-1'"
  (click)="signIn()"
>
  Sign In
</button>
<p>Don't have a Labcorp Patient account?</p>
<div class="mt-1">
  <button [ngClass]="showConfirmation ? 'btn btn-outline-primary' : 'btn btn-outline-primary-dark'" (click)="register()">
    Create an Account
  </button>
</div>
