import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as phoneActions from './phone.actions';
import { PhoneService } from './phone.service';

@Injectable()
export class PhoneEffects {
  billingTextsOptIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneActions.billingTextsOptIn),
      mergeMap((action) =>
        this.phoneService.billingNotificationsOptIn(action.optInData).pipe(
          map((response) =>
            phoneActions.billingTextsOptInSuccess({ response })
          ),
          catchError((_error) => of(phoneActions.billingTextsOptInFailure()))
        )
      )
    )
  );

  saveTextNotificationsOptIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneActions.saveTextNotificationsOptIn),
      mergeMap((action) =>
        this.phoneService.saveTextNotificationsOptIn(action.optInData).pipe(
          map((response) =>
            phoneActions.saveTextNotificationsOptInSuccess({ response })
          ),
          catchError((_error) =>
            of(phoneActions.saveTextNotificationsOptInFailure())
          )
        )
      )
    )
  );

  getTextNotificationsOptInStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneActions.getTextNotificationsOptInStatus),
      mergeMap((action) =>
        this.phoneService
          .getTextNotificationsOptInStatus(action.optInData)
          .pipe(
            map((response) =>
              phoneActions.getTextNotificationsOptInStatusSuccess({ response })
            ),
            catchError((_error) =>
              of(phoneActions.getTextNotificationsOptInStatusFailure())
            )
          )
      )
    )
  );

  constructor(private actions$: Actions, private phoneService: PhoneService) {}
}
