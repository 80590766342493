<div id="tech-difficulties" class="alert alert-danger fade show" role="alert" *ngIf="techDifficultiesMsg$ | async">
  <i class="alert-icon material-icons">warning</i>
  <div class="text">
    We are unable to sign you up at this time. Please try again later.
  </div>
</div>
<form [formGroup]="optInForm" (submit)="onSubmit()">
  <div class="form-row" formGroupName="phone">
    <div class="mobile-number col pt-0 pb-4">
      <label for="number">Mobile Number</label>
      <input
        type="tel"
        class="form-control"
        formControlName="number"
        id="number"
        mask="000-000-0000"
        placeholder="{{ currentMobileNumber }}"
        [class.is-invalid]="invalidPhoneMsg$ | async"
      />
      <small id="invalid-number-error" *ngIf="invalidPhoneMsg$ | async" class="form-text invalid-feedback d-block">
        <i class="material-icons icon-sm">error_outline</i>
        <span> Please enter a valid mobile number.</span>
      </small>
    </div>
  </div>
  <ng-container *ngIf="currentOptInStatus !== 'optedInAll'">
    <div class="form-row">
      <div class="col pt-1 pb-4 pr-sm-6">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            formControlName="terms"
            id="terms"
            [class.is-invalid]="invalidProvideConsent$ | async"
          />
          <label class="custom-control-label small" for="terms">
            By checking this box, you agree that Labcorp may send you recurring informational messages regarding your
            <strong>lab services</strong>. You can reply STOP anytime to cancel future texts or HELP if you need assistance. Message and
            data rates may apply. Text message frequency will vary. For more information, view our
            <a href="https://www.labcorp.com/terms-and-conditions" target="_blank" rel="noopener"><u>Terms & Conditions</u></a>
            and
            <a href="https://www.labcorp.com/hipaa-privacy" target="_blank" rel="noopener"><u>Privacy Policy</u></a
            >.
          </label>
          <small *ngIf="invalidProvideConsent$ | async" class="form-text invalid-feedback d-block">
            <i class="material-icons icon-sm">error_outline</i>
            Please check the box to continue
          </small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col pt-1 pb-4 pr-sm-6">
        <re-captcha
          id="recaptcha"
          *ngIf="recaptchaKey.length > 0"
          formControlName="captcha"
          [siteKey]="recaptchaKey"
          required
          (resolved)="resolveRecaptcha($event)"
        >
        </re-captcha>
      </div>
    </div>
  </ng-container>
  <div class="form-row">
    <div class="col pt-1">
      <button id="submit" type="submit" class="btn btn-min-width btn-primary">
        <span *ngIf="showSpinner" class="spinner-border spinner-border-sm mr-1"> <output aria-live="polite"></output> </span
        ><span>{{ currentOptInStatus === 'optedInAll' ? 'Update' : 'Submit' }}</span>
      </button>
      <button type="button" class="ml-6 btn btn-outline-primary">
        No Thanks
      </button>
    </div>
  </div>
</form>
