import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OptInStatus } from '@patient-ui/shared/models';

import { PhoneState, phoneFeatureKey } from './phone.reducer';

export const selectPhoneState = createFeatureSelector<PhoneState>(
  phoneFeatureKey
);

export const selectBillingOptedIn = createSelector(
  selectPhoneState,
  (state) => state.billingOptedIn
);

export const selectBillingMessage = createSelector(
  selectPhoneState,
  (state) => state.billingMessage
);

export const selectBillingOptInRequestStatus = createSelector(
  selectPhoneState,
  (state) => state.billingOptInPhoneRequestStatus
);

export const selectSaveTextNotificationsOptInStatus = createSelector(
  selectPhoneState,
  (state) => state.saveTextNotificationsOptInRequestStatus
);

export const selectGetTextNotificationsOptInStatus = createSelector(
  selectPhoneState,
  (state) => state.getTextNotificationsOptInRequestStatus
);

export const selectBillingServices = createSelector(
  selectPhoneState,
  (state) => state.billingOptin
);

export const selectLabServices = createSelector(
  selectPhoneState,
  (state) => state.labServiceOptin
);

export const selectCombinedOptInStatus = createSelector(
  selectBillingServices,
  selectLabServices,
  (billingOptin, labServiceOptin): OptInStatus => {
    const optedInBilling = billingOptin.optedIn;
    const optedInLab = labServiceOptin.optedIn;

    if (optedInBilling && optedInLab) {
      return 'optedInAll';
    } else if (!optedInBilling && !optedInLab) {
      return 'optedInNone';
    } else if (optedInBilling) {
      return 'optedInBillingServicesOnly';
    } else {
      return 'optedInLabServicesOnly';
    }
  }
);
