import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'patient-ui-confirm-and-sign-in-section',
  templateUrl: './confirm-and-sign-in-section.component.html',
  styleUrls: ['./confirm-and-sign-in-section.component.scss'],
})
export class ConfirmAndSignInSectionComponent {
  @Input() showConfirmation = false;
  constructor(
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router
  ) {}
  register() {
    this.router.navigate(['/account/registration/register']);
  }

  async signIn() {
    await this.oktaAuth.signInWithRedirect({
      originalUri: '/',
    });
  }
}
